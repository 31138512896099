<template>
  <div class="container_details">
    <clone-tab :bgcShow="bgcShow"></clone-tab>

    <!-- from the -->
    <div class="from_detail">
      <div class="from_list">
        <div class="icon">
          <img src="../../assets/icon/icon6.png" />
        </div>
        <div>姓名:<span>{{dataList.apply_name}}</span></div>
      </div>
      <div class="from_list">
        <div class="icon">
          <img src="../../assets/icon/icon3.png" />
        </div>
        <div>电话:<span>{{dataList.apply_phone}}</span></div>
      </div>
      <div class="from_list">
        <div class="icon">
          <img src="../../assets/icon/icon2.png" />
        </div>
        <div>地址:<span>{{dataList.unionActivity.activity_adder}}</span></div>
      </div>
      <div class="from_list">
        <div class="icon">
          <img src="../../assets/icon/icon4.png" />
        </div>
        <div>参加人数:<span>{{dataList.person_sum}}人</span></div>
      </div>
      <div class="from_list">
        <div class="icon">
          <img src="../../assets/icon/icon1.png" />
        </div>
        <div>备注:<span v-html="dataList.remark"></span></div>
      </div>
      <div class="from_list">
        <div class="icon">
          <img src="../../assets/icon/icon5.png" />
        </div>
        <div>活动主题:<span>{{dataList.unionActivity.activity_title}}</span></div>
      </div>
      <div class="from_list">
        <div class="icon">
          <img src="../../assets/icon/icon7.png" />
        </div>
        <div>活动地址</div>
      </div>
      <div class="map_box">
        <baidu-map class="map_box" @ready='init' :center="mapData.center" :zoom="mapData.zoom"></baidu-map>
        <div class="messageBtn" @click="baiduMap">点击导航</div>

      </div>
    </div>
    <div class="cloneActivity" @click="cloneActivity">取消活动</div>
  </div>
</template>
<script>
import cloneTab from "../../components/expenditure/cloneTab";
import { applyDetails_api, cloneActiveDeatil_api } from "@/api/deal";
import { Dialog } from "vant";
export default {
  data() {
    return {
      //地图数据
      mapData: {
        //中心坐标
        center: { lng: 0, lat: 0 },
        //缩放级别
        zoom: 13
      },
      //BMap类
      BMap: null,
      //地图对象
      map: null,
      bgcShow: true,
      lng: null,
      lat: null,
      dataList: null
    };
  },
  components: {
    cloneTab
  },
  created() {
    document.title = "活动详情";
    this.getDetails(this.$route.query.id);
  },
  methods: {
    async init({ BMap, map }) {
      //保存百度地图类
      this.BMap = BMap;
      //保存地图对象
      this.map = map;
      //   let xy = coordinateTrans.WGS84toBD([114.35567, 30.52922]);
      let point = new BMap.Point(this.lng, this.lat); //设置城市经纬度坐标
      // let point = new BMap.Point(114.3016729410009, 30.662993889353807); //设置城市经纬度坐标
      map.centerAndZoom(point, 15); //
      map.setCurrentCity("武汉"); // 设置地图显示的城市 此项是必须设置的
      map.enableScrollWheelZoom(true); //鼠标滚动缩放
      let marker = new BMap.Marker(point);
      map.addOverlay(marker);
    },
    getDetails(id) {
      applyDetails_api(id).then(res => {
        if (res.code == 0) {
          console.log(res);
          this.dataList = res.data;
          this.lng = this.dataList.unionActivity.address_dimension;
          this.lat = this.dataList.unionActivity.address_equatorial_circumference;
        }
      });
    },

    baiduMap() {
      console.log(this.dataList.unionActivity.activity_adder);
      window.location = `http://api.map.baidu.com/geocoder?address=${
        this.dataList.unionActivity.activity_adder
      }&output=html&src=webapp.baidu.openAPIdemo`;
    },
    cloneActivity() {
      Dialog.confirm({
        title: "确认",
        message: "确认取消活动"
      })
        .then(() => {
          let data = {
            activity_id: this.dataList.activity_id,
            refund_remark: []
          };
          cloneActiveDeatil_api(data).then(res => {
            if (res.code == 0) {
              this.$toast("操作成功");
              this.$router.go(-1);
            }else{
              this.$toast(res.error);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    }
  }
};
</script>
<style>
.van-toast {
  width: 300px;
  line-height:50px;
  min-height: 150px;
}
.van-toast__text {
  font-size: 35px;
}
.container_details {
  padding-top: 20px;
  box-sizing: border-box;
  position: relative;
}
.from_detail {
  width: 721px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  opacity: 1;
  border-radius: 0px;
  margin: 30px auto;
  padding: 24px 28px;
  box-sizing: border-box;
  font-size: 30px;
  color: #151516;
}

.from_list {
  display: flex;
  border-bottom: 1px solid rgba(112, 112, 112, 0.25);
  padding-bottom: 20px;
  margin-top: 20px;
}
.from_list span {
  margin-left: 20px;
}
.icon {
  width: 32px;
  height: 32px;
  margin-right: 20px;
}
.icon img {
  width: 100%;
  height: 100%;
}
.map_box {
  width: 599px;
  height: 396px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  opacity: 1;
  border-radius: 0px;
  margin: 30px auto;
  position: relative;
}

.messageBtn {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  width: 200px;
  height: 35px;
  background: #4b93ff;
  box-shadow: 0px 0px 10px rgba(75, 147, 255, 0.5);
  opacity: 1;
  border-radius: 0px;
  text-align: center;
  line-height: 35px;
  font-size: 20px;
  color: #fff;
}
.cloneActivity {
  width: 240px;
  height: 62px;
  background: #f66009;
  opacity: 1;
  border-radius: 14px;
  font-size: 25px;
  color: #fff;
  text-align: center;
  line-height: 62px;

  margin: 50px auto;
}
.van-dialog {
  width: 500px;
  font-size: 30px;
}

.van-dialog__message--has-title {
  font-size: 28px;
  margin-top: 20px;
}
.van-dialog__confirm,
.van-dialog__cancel {
  font-size: 30px !important;
}
</style>

